import React, { useState, useRef, useEffect } from 'react';
import {
    Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title, Tooltip, Legend
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Icon } from '@iconify/react';
import { toPng } from 'html-to-image'; // Import the correct function from html-to-image
import '../style/components-style/Footprint.css';
import { translations } from '../app_components/Translation';
import config from "../config";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Function to generate distinct RGB colors based on index and total number of items
function getDistinctRGB(index, total) {
    const hue = (index / total) * 360; // Space out colors evenly around the hue circle
    const saturation = 70 + Math.random() * 30; // Random saturation between 70% and 100%
    const lightness = 50 + Math.random() * 10; // Random lightness between 50% and 60%

    // Convert HSL to RGB
    const hslToRgb = (h, s, l) => {
        s /= 100;
        l /= 100;
        const k = n => (n + h / 30) % 12;
        const a = s * Math.min(l, 1 - l);
        const f = n => l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);
        return [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];
    };
    const [r, g, b] = hslToRgb(hue, saturation, lightness);
    return { r, g, b };
}

function Footprint({ processData, selectedLanguage, setFootprintChart, setFootprintDoughnut, pageType }) {
    console.log('Footprint.js**: processData:', processData);
    const [isFootprintOverviewOpen, setIsFootprintOverviewOpen] = useState(true);
    const [isEnvironmentalFootprintOpen, setIsEnvironmentalFootprintOpen] = useState(true);
    const [selectedLCAStage, setSelectedLCAStage] = useState('All');
    const doughnutChartContainerRef = useRef(null); // Ref for Doughnut chart container
    const footprintBarChartRef = useRef(null); // Ref for Footprint Bar chart

    const toggleFootprintOverview = () => setIsFootprintOverviewOpen(!isFootprintOverviewOpen);
    const toggleEnvironmentalFootprint = () => setIsEnvironmentalFootprintOpen(!isEnvironmentalFootprintOpen);
    const isA1A2A3Stage = (stage) => ['A1', 'A2', 'A3'].includes(stage.split(':')[0]);
    const handleLCAStageChange = (event) => {
        setSelectedLCAStage(event.target.value);
    };

    const [materialMode, setMaterialMode] = useState(false);
    const [cumulativeGWP, setCumulativeGWP] = useState({});
    const [cumulativeWeight, setCumulativeWeight] = useState({});
    const toggleEnvOrMaterial = () => setMaterialMode(!materialMode);

    const cumulativeGWPLabels = Object.keys(cumulativeGWP);
    const cumulativeGWPDataPoints = Object.values(cumulativeGWP).map(value => parseFloat(value.toFixed(2)));

    const cumulativeWeightLabels = Object.keys(cumulativeWeight);
    const cumulativeWeightDataPoints = Object.values(cumulativeWeight).map(value => parseFloat(value.toFixed(2)));

    const cumulativeBackgroundColors = [];
    const cumulativeBorderColors = [];

    const selectedText = translations[selectedLanguage].footprint;

    const filteredProcessData = selectedLCAStage === 'All'
        ? processData
        : selectedLCAStage === 'A123'
            ? processData.filter(item => ['A1', 'A2', 'A3'].some(prefix => item.lca_stage.startsWith(prefix)))
            : selectedLCAStage === 'A45'
                ? processData.filter(item => ['A4', 'A5'].some(prefix => item.lca_stage.startsWith(prefix)))
                : processData.filter(item => item.lca_stage.startsWith(selectedLCAStage));

    const barLabels = filteredProcessData.map(item => item.name);
    const barDataPoints = filteredProcessData.map(item => parseFloat(item.gwp.toFixed(2)));

    const backgroundColors = [];
    const borderColors = [];

    barLabels.forEach((label, index) => {
        const { r, g, b } = getDistinctRGB(index, barLabels.length);
        const opacity = 0.3; // Background color opacity
        backgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        borderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Border color is fully opaque
    });

    const barData = {
        labels: barLabels,
        datasets: [{
            label: 'GWP',
            data: barDataPoints,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1.5,
            pointRadius: 3,
            pointHoverRadius: 3,
            pointBackgroundColor: backgroundColors,
            tension: 0.05
        }]
    };

    const barOptions = {
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: true,
                    drawBorder: true,
                    borderColor: 'black', // Set the border color for the gridlines
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: true,
                    drawBorder: true,
                    borderColor: 'black', // Set the border color for the gridlines
                },
                title: {
                    display: true,
                    text: 'GWP [kg CO₂e]',
                    font: {
                        size: 13,
                        weight: 'bold',
                    },
                    padding: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 14
                    }
                }
            }
        },
        layout: {
            padding: 20 // Add some padding around the chart
        },
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: true,
        responsive: true
    };

    const totalGWPs = pageType === 'supplier' ? {
        a1: 0,
        a2: 0,
        a3: 0
    } : {
        product: 0,
        construction: 0,
        use: 0,
        endOfLife: 0,
        benefits: 0
    };

    const totalBenefitsGWPs = {
        benefits: 0
    };

    processData.forEach(item => {
        const stagePrefix = item.lca_stage.split(':')[0];

        if (pageType === 'supplier' && isA1A2A3Stage(stagePrefix)) {
            if (stagePrefix.startsWith('A1')) totalGWPs.a1 += item.gwp;
            if (stagePrefix.startsWith('A2')) totalGWPs.a2 += item.gwp;
            if (stagePrefix.startsWith('A3')) totalGWPs.a3 += item.gwp;
        } else if (pageType === 'utilizer') {
            if (['A1', 'A2', 'A3'].some(prefix => stagePrefix.startsWith(prefix))) {
                totalGWPs.product += item.gwp;
            } else if (['A4', 'A5'].some(prefix => stagePrefix.startsWith(prefix))) {
                totalGWPs.construction += item.gwp;
            } else if (stagePrefix.startsWith('B')) {
                totalGWPs.use += item.gwp;
            } else if (stagePrefix.startsWith('C')) {
                totalGWPs.endOfLife += item.gwp;
            } else if (stagePrefix.startsWith('D')) {
                totalBenefitsGWPs.benefits += item.gwp;
            }
        }
    });

    const totalGWP = Object.values(totalGWPs).reduce((sum, value) => sum + value, 0);

    const convertToKg = (value, unit) => {
        const unitToKg = {
            mg: 1e-6,
            g: 1e-3,
            ton: 1000,
            lb: 0.453592,
            oz: 0.0283495,
        };
        return value * (unitToKg[unit] || 1); // Default to 1 for kg
    };
    
    const doughnutBackgroundColors = [];
    const doughnutBorderColors = [];

    // Doughnut chart colors
    const doughnutData = {
        labels: materialMode
            ? cumulativeWeightLabels
            : pageType === 'supplier'
                ? ['A1', 'A2', 'A3']
                : [selectedText.product, selectedText.construction, selectedText.use, selectedText.endOfLife, selectedText.benefits],
        datasets: [
            {
                data: materialMode
                    ? cumulativeWeightDataPoints
                    : pageType === 'supplier'
                        ? [totalGWPs.a1, totalGWPs.a2, totalGWPs.a3].map(value => parseFloat(value.toFixed(2)))
                        : [totalGWPs.product, totalGWPs.construction, totalGWPs.use, totalGWPs.endOfLife].map(value => parseFloat(value.toFixed(2))),
                backgroundColor: doughnutBackgroundColors,
                borderColor: doughnutBorderColors,
                borderWidth: 1,
            },
        ],
    };

    doughnutData.labels.forEach((label, index) => {
        const { r, g, b } = getDistinctRGB(index, doughnutData.labels.length);
        const opacity = 0.3; // Adjust the opacity for background color
        doughnutBackgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        doughnutBorderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Full opacity for the border
    });

    // Create a filtered dataset excluding 0 values for the doughnut chart
    const filteredDoughnutData = {
        labels: doughnutData.labels.filter((_, index) => doughnutData.datasets[0].data[index] > 0),
        datasets: [
            {
                ...doughnutData.datasets[0],
                data: doughnutData.datasets[0].data.filter(value => value > 0),
                backgroundColor: doughnutData.datasets[0].data
                    .map((value, index) => (value > 0 ? doughnutBackgroundColors[index] : null))
                    .filter(color => color !== null),
                borderColor: doughnutData.datasets[0].data
                    .map((value, index) => (value > 0 ? doughnutBorderColors[index] : null))
                    .filter(color => color !== null),
            },
        ],
    };


    const doughnutOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: true,
        responsive: true
    };

    const downloadChartWithLegend = () => {
        if (doughnutChartContainerRef.current) {
            toPng(doughnutChartContainerRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'footprint_overview.png';
                    link.click();
                })
                .catch((error) => {
                    console.error('Error generating image:', error);
                });
        }
    };

    const downloadFootprintBarChart = () => {
        if (footprintBarChartRef.current) {
            toPng(footprintBarChartRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'environmental_footprint.png';
                    link.click();
                })
                .catch((error) => {
                    console.error('Error generating image:', error);
                });
        }
    };


    // Function to capture chart images and update state
    const captureChartImage = async () => {
        if (doughnutChartContainerRef.current) {
            try {
                const doughnutDataUrl = await toPng(doughnutChartContainerRef.current, { quality: 1 });
                setFootprintDoughnut(doughnutDataUrl);  // Update state with doughnut chart image
            } catch (error) {
                console.error('Error capturing doughnut chart image:', error);
            }
        }
        if (footprintBarChartRef.current) {
            try {
                const barDataUrl = await toPng(footprintBarChartRef.current, { quality: 1 });
                setFootprintChart(barDataUrl);  // Update state with bar chart image
            } catch (error) {
                console.error('Error capturing bar chart image:', error);
            }
        }
    };

    // Use useEffect to trigger chart capture when data or selectedLCAStage changes
    useEffect(() => {
        const timer = setTimeout(() => {
            captureChartImage();
        }, 1000); // 1000 milliseconds = 1 second

        // Cleanup the timer if the component unmounts or dependencies change
        return () => clearTimeout(timer);
    }, [processData, selectedLCAStage]);

    const totalBiogenicGWP = processData.reduce((sum, item) => {
        return sum + (item.biogenic_gwp || 0);
    }, 0);

    const fetchEnvFactorsByProcessId = async (processId) => {
        try {
            const response = await fetch(`${config.apiUrl}/api/get-env-factor-by-process-id?process_id=${processId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching environmental factors:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchAndProcessData = async () => {
            const cumulativeGWP = {};
            const cumulativeWeight = {};
            const filteredProcesses = processData.filter(process => process.lca_stage.startsWith('A1'));
    
            for (const process of filteredProcesses) {
                try {
                    const envFactors = await fetchEnvFactorsByProcessId(process.id);
                    if (envFactors) {
                        envFactors.forEach(factor => {
                            const { description, gwp_total, quantity, factor: factorValue, unit } = factor;
    
                            // Calculate cumulative GWP
                            if (cumulativeGWP[description]) {
                                cumulativeGWP[description] += gwp_total;
                            } else {
                                cumulativeGWP[description] = gwp_total;
                            }
    
                            // Convert quantity to kilograms
                            const weightInKg = convertToKg(quantity, unit);
    
                            // Calculate cumulative Weight in kg
                            const weight = weightInKg * factorValue;
                            if (cumulativeWeight[description]) {
                                cumulativeWeight[description] += weight;
                            } else {
                                cumulativeWeight[description] = weight;
                            }
                        });
                    }
                } catch (error) {
                    console.error(`Error fetching data for process ${process.id}:`, error);
                }
            }
            setCumulativeGWP(cumulativeGWP);
            setCumulativeWeight(cumulativeWeight);
            console.log('Cumulative GWP:', cumulativeGWP);
            console.log('Cumulative Weight (in kg):', cumulativeWeight);
        };
    
        fetchAndProcessData();
    }, [processData]);


    cumulativeGWPLabels.forEach((label, index) => {
        const { r, g, b } = getDistinctRGB(index, cumulativeGWPLabels.length);
        const opacity = 0.3; // Adjust the opacity for the background color
        cumulativeBackgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        cumulativeBorderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Full opacity for the border
    });

    cumulativeWeightLabels.forEach((label, index) => {
        const { r, g, b } = getDistinctRGB(index, cumulativeWeightLabels.length);
        const opacity = 0.3; // Adjust the opacity for the background color
        cumulativeBackgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        cumulativeBorderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Full opacity for the border
    });

    const cumulativeGWPData = {
        labels: cumulativeGWPLabels,
        datasets: [
            {
                label: 'Cumulative GWP',
                data: cumulativeGWPDataPoints,
                backgroundColor: cumulativeBackgroundColors,
                borderColor: cumulativeBorderColors,
                borderWidth: 1,
            },
        ],
    };

    const cumulativeWeightData = {
        labels: cumulativeWeightLabels,
        datasets: [
            {
                data: cumulativeWeightDataPoints,
                backgroundColor: cumulativeBackgroundColors,
                borderColor: cumulativeBorderColors,
                borderWidth: 1,
            },
        ],
    };

    const totalWeight = Object.values(cumulativeWeight).reduce((sum, value) => sum + value, 0);

    return (
        <div className="footprint-chart-container">
            <div className="footprint-container">
                <div className="container-header-footprint-overview">
                    <h2
                        onClick={toggleFootprintOverview}
                        onMouseEnter={() => document.querySelector('.container-header-footprint-overview h2').style.color = 'grey'}
                        onMouseLeave={() => document.querySelector('.container-header-footprint-overview h2').style.color = 'black'}
                        style={{ cursor: 'pointer' }}
                    >
                        {selectedText.footprintOverview}
                    </h2>
                    {pageType === 'supplier' && (
                        <div className={`toggle-switch-footprint ${materialMode ? 'material' : 'environmental'}`} onClick={toggleEnvOrMaterial}>
                            <div className="toggle-option-footprint selected">environmental</div>
                            <div className="toggle-option-footprint">material</div>
                            <div className="toggle-slider-footprint"></div>
                        </div>
                    )}
                </div>
                {isFootprintOverviewOpen && (
                    <div className="doughnut-chart-container">
                        <div ref={doughnutChartContainerRef} className="doughnut-chart">
                            <div className="download-icon" onClick={downloadChartWithLegend}>
                                <Icon icon="fe:download" style={{ color: 'grey', cursor: 'pointer' }} />
                            </div>
                            <div className="doughnut-chart-wrapper">
                                {materialMode ? (
                                    <Doughnut data={cumulativeWeightData} options={doughnutOptions} className="footprint-doughnut" />
                                ) : (
                                    <Doughnut data={filteredDoughnutData} options={doughnutOptions} className="footprint-doughnut" />
                                )}
                            </div>
                            <div className="legend-total-container">
                                {materialMode ? (                          <div className="gwp-total-container">
                                    <div className="gwp-total-footprint">
                                        <span className="gwp-total-label-footprint">Total Weight:</span>
                                        <span className="gwp-total-value-footprint"> {totalWeight} </span>
                                    </div>
                                </div>
                                ) : (
                                    <div className="gwp-total-container">
                                    <div className="gwp-total-footprint">
                                        <span className="gwp-total-label-footprint">GWP Total:</span>
                                        <span className="gwp-total-value-footprint">{totalGWP.toFixed(2)}</span>
                                    </div>
                                    <div className="gwp-total-footprint">
                                        <span className="gwp-total-label-footprint">Biogenic GWP Total:</span>
                                        <span className="gwp-total-value-footprint">{totalBiogenicGWP.toFixed(2)}</span>
                                    </div>
                                </div>
                                )}
                                <div className="vertical-divider"> </div>
                                <div className="legend">
                                    {pageType === 'supplier' ? (
                                        doughnutData.labels.map((label, index) => {
                                            const value = doughnutData.datasets[0].data[index].toFixed(2);
                                            const denominator = materialMode ? totalWeight : totalGWP;
                                            const percentage = isNaN((value / denominator) * 100) ? '0' : ((value / denominator) * 100).toFixed(2);
                                            return (
                                                <div key={label} className="legend-item">
                                                    <span
                                                        className="legend-color"
                                                        style={{ backgroundColor: doughnutData.datasets[0].backgroundColor[index] }}
                                                    ></span>
                                                    <span className="legend-text">
                                                        {label}: {value} [{percentage}%]
                                                    </span>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        doughnutData.labels.map((label, index) => {
                                            // Determine the original value for the label
                                            let originalValue = 0;
                                            if (label === selectedText.benefits) {
                                                originalValue = totalBenefitsGWPs.benefits; // Get benefits value
                                            } else if (label === selectedText.product) {
                                                originalValue = totalGWPs.product;
                                            } else if (label === selectedText.construction) {
                                                originalValue = totalGWPs.construction;
                                            } else if (label === selectedText.use) {
                                                originalValue = totalGWPs.use;
                                            } else if (label === selectedText.endOfLife) {
                                                originalValue = totalGWPs.endOfLife;
                                            }

                                            const value = parseFloat(originalValue.toFixed(2));
                                            const denominator = materialMode ? totalWeight : totalGWP;
                                            const percentage = isNaN((value / denominator) * 100) ? '0' : ((value / denominator) * 100).toFixed(2);
                                            return (
                                                <div key={label} className="legend-item">
                                                    <span
                                                        className="legend-color"
                                                        style={{ backgroundColor: doughnutData.datasets[0].backgroundColor[index] }}
                                                    ></span>
                                                    <span className="legend-text">
                                                        {label}: {value} [{percentage}%]
                                                    </span>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                            {materialMode ? (
                                <div className="units-info">All units are calculated in kg</div>
                                ):( 
                                    <div className="units-info">All units are calculated in kg CO₂e</div>
                                )}
                        </div>
                    </div>
                )}
            </div>
            <div className="bar-chart">
                <div className="container-header-envfootprint">
                    <h2
                        onClick={toggleEnvironmentalFootprint}
                        onMouseEnter={() => document.querySelector('.container-header-envfootprint h2').style.color = 'grey'}
                        onMouseLeave={() => document.querySelector('.container-header-envfootprint h2').style.color = 'black'}
                        style={{ cursor: 'pointer' }}
                    >
                        {selectedText.environmentalFootprint}
                    </h2>
                </div>
                {isEnvironmentalFootprintOpen && (
                    <div ref={footprintBarChartRef} className="footprint-barchart">
                        <div className="download-icon" onClick={downloadFootprintBarChart}>
                            <Icon icon="fe:download" style={{ color: 'grey', cursor: 'pointer' }} />
                        </div>
                        <div className="lca-stage-container">
                            <select value={selectedLCAStage} onChange={handleLCAStageChange} className="lca-stage-select">
                                {pageType === 'supplier' ? (
                                    <>
                                        <option value="A123">{selectedText.dropdown.product}</option>
                                        <option value="A1">A1: Raw Material Acquisition</option>
                                        <option value="A2">A2: Transport to Factory</option>
                                        <option value="A3">A3: Manufacturing</option>
                                    </>
                                ) : (
                                    <>
                                        <option value="All">{selectedText.dropdown.all}</option>
                                        <option value="A123">{selectedText.dropdown.product}</option>
                                        <option value="A45">{selectedText.dropdown.construction}</option>
                                        <option value="B">{selectedText.dropdown.use}</option>
                                        <option value="C">{selectedText.dropdown.endOfLife}</option>
                                        <option value="D">{selectedText.dropdown.benefits}</option>
                                    </>
                                )}
                            </select>
                        </div>
                        {materialMode ? (
                            <Bar data={cumulativeGWPData} options={barOptions} className='environmental-footprint-chart' height={225} /> //material mode
                        ) : (
                            <Bar data={barData} options={barOptions} className='environmental-footprint-chart' height={225} /> //env mode
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Footprint;
