function getApiBaseUrl() {
  const hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    return "https://api-arivu.vrtta.green"; // Development API
  } else {
    return "https://api-arivu.vrtta.green"; // Production API
  }
}

const config = {
  version: "flots",
  apiUrl: getApiBaseUrl(),
};

export default config;
